import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"

const DemoTest = () => {
  const body = [
    {
      component: "oxomi_block",
      embed_code:
        '<script>\n// OXOMI JavaScript Bibliothek einbinden und laden\nsetTimeout(function() {\n    var script = document.createElement("script");\n    script.type = "text/javascript";\n    script.src = (window.location.protocol == \'https:\' ? \'https:\' : \'http:\')\n                + "//" + (typeof oxomi_server == \'undefined\' ? \'oxomi.com\' : oxomi_server)\n                + "/assets/frontend/oxomi.js";\n    document.getElementsByTagName("head")[0].appendChild(script);\n}, 0);\n \n// Wir warten bis die OXOMI JavaScript Bibliothek geladen ist...\nfunction oxomi_ready() {\n \n    // Initialisierung\n    oxomi.init({\n        "portal": "24510",\n        "lang": "de"\n});\n\n    // Funktionsaufruf, um PortaloberflÃ¤che anzuzeigen\n    oxomi.portalSearch({\n        "target": "#output",\n        "input": "#input",\n        "lang": "de",\n        "showDetails": true\n    }); \n};\n</script>\n<div id="input"></div>\n<div id="output">Im Moment gibts noch nichts zu sehen...</div>',
    },
  ]

  return (
    <Layout>
      <BodyBlocks body={body} />
    </Layout>
  )
}

export default DemoTest
